
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


*::-webkit-scrollbar {
  width: 0.5rem;
  background: #fff !important;
  height: 0.5rem;
}

*::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 20px;
}